import React from 'react'
import '../components/clients.scss'

import Layout from '../components/layout'
import SEO from '../components/seo'

const clientsInfo = [
  'Air Liquide Large Industries US, LP',
  'Algonquin Energy',
  'American Medical Response, Inc',
  'Ami Gordon - PAWedu',
  'Animal Legal Defense Fund',
  'Center for Adequacy and Equity in Public School Finance',
  'CenturyLink',
  'Coalition for Nurses in Advanced Practice',
  'Electro Purification, LLC',
  'Exergy Energy, LLC',
  'Goforth Special Utility District',
  'Jackson Walker, LLP',
  'MSB School Services',
  'National Association of Bus Crash Families',
  "Nature's Electric",
  'Pharr San Juan Alamo ISD',
  'Selman & Company, LLC',
  'Shell Industries',
  'SNCF America, Inc (French National Railway Corp.)',
  'State Bar of Texas',
  'State Senator Sylvia Garcia',
  'Texas Alliance of Boys and Girls Clubs',
  'Texas Animal Control Association',
  'Texas Association of Clinical Laboratory Science',
  'Texas Association of Rural Schools',
  'Texas Federation of Animal Care Societies',
  'Texas Federation of Humane Societies',
  'Texas H2 Coalition',
  'Texas Medical Association PracticeEdge',
  'Texas School for the Deaf',
  'Texas Telephone Association',
  'The Eppstein Group',
  'The Equity Center',
  'Toyota Motor Sales USA, Inc',
  'Xcel Energy',
]

const ClientsPage = () => (
  <Layout>
    <SEO title="Clients" />
    <div className="subheader-img-clients" />
    <div className="clients-page-wrapper">
      <h2>Our Clients Include</h2>
      <p>
        Our clients have included individuals, small non-profit associations,
        and large multi-national companies.
      </p>
      <div className="clients-list-wrapper-top">
        <div className="clients-list-top-img-wrapper">
          <img
            src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602736220/plctexas/TMAPE.png"
            alt="TMAPE"
          />
        </div>
        <div className="clients-list-top-img-wrapper">
          <img
            src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602798231/plctexas/TARS.png"
            alt="TARS"
          />
        </div>
        <div className="clients-list-top-img-wrapper">
          <img
            src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602736220/plctexas/TTA.png"
            alt="TTA"
          />
        </div>
        <div className="clients-list-top-img-wrapper">
          <img
            src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602797987/plctexas/SNCF.png"
            alt="SNCF"
          />
        </div>
        <div className="clients-list-top-img-wrapper">
          <img
            src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602736220/plctexas/Safebuses.jpg"
            alt="Safe Buses"
          />
        </div>

        <div className="clients-list-top-img-wrapper">
          <div className="lumen-wrapper">
            <img
              src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602798032/plctexas/lumen.png"
              className="client-logo"
              alt="Lumen"
            />
            <div className="lumen-underscore">
              <p>Formerly</p>
              <img
                src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1602798031/plctexas/Centurylink.png"
                alt="CenturyLink"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="clients-list-wrapper-bottom">
        {clientsInfo
          .sort((a, b) => {
            const nameA = a.toLowerCase()
            const nameB = b.toLowerCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
          .map((client, i) => {
            return <p key={i}>{client}</p>
          })}
      </div>
    </div>
  </Layout>
)

export default ClientsPage
